import React from "react";

// Components
import Layout from "../components/Layout";
import Header from "../components/Header";
import About from "../components/About";
import Convocatoria from "../components/Convocatoria";
import Subscribe from "../components/Subscribe";
import Sponsors from "../components/Sponsors";
import Timeline from "../components/Timeline";
import Speakers from "../components/Speakers";

const IndexPage = () => (
  <Layout>
    <section id="home">
      <div className="inner">
        <Header />
        <About />
        <Convocatoria />
        <Subscribe />
        <Speakers />
        <Timeline />
        <Sponsors />
      </div>
    </section>
  </Layout>
);

export default IndexPage;
