import React, { Component } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

import "../assets/sass/main.scss";
//import Footer from './Footer';
//import SideBar from "./Slidebar";
import config from "../config";
import Footer from "./Footer";

// Assets
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  render() {
    const { children } = this.props;
    const { isPreloaded } = this.state;
    return (
      <>
        <Helmet
          title={config.siteTitle}
          meta={[
            {
              // Google meta data
              name: "description",
              content:
                "Arduino Day LATAM está aquí! Electronic Cats, ILCE y Hacedores se han unido para la celebración más grande del Arduino Day en Latinoamérica. Unete a la fiesta de la comunidad maker latinoamericana.",
            },
            {
              name: "keywords",
              content:
                "site, web, arduino, arduino day 2021, arduino day, electronic cats, open hardware ",
            },
            {
              // Facebook meta data GraphQl
              property: "og:title",
              content: "Arduino Day 2021",
            },
            {
              property: "og:type",
              content: "landingpage",
            },
            {
              property: "og:url",
              content: "https://www.arduino.mx/",
            },
            {
              property: "og:description",
              content:
                "Arduino Day LATAM está aquí! Electronic Cats, ILCE y Hacedores se han unido para la celebración más grande del Arduino Day en Latinoamérica. Unete a la fiesta de la comunidad maker latinoamericana.",
            },
            {
              property: "og:site_name",
              content: "Arduino Day 2021",
            },
            {
              // Twitter Meta Data
              property: "twitter:card",
              content: "summary",
            },
            {
              property: "twitter:site",
              content: "",
            },
            {
              property: "twitter:title",
              content: "Arduino Day 2021",
            },
            {
              property: "twitter:description",
              content:
                "Arduino Day LATAM está aquí! Electronic Cats, ILCE y Hacedores se han unido para la celebración más grande del Arduino Day en Latinoamérica. Unete a la fiesta de la comunidad maker latinoamericana.",
            },
            {
              property: "twitter:creator",
              content: "@electroniccats",
            },
          ]}
          link={[
            {
              rel: "icon",
              type: "image/png",
            },
          ]}
        >
          {/* Global site tag (gtag.js) - Google Analytics  */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=UA-156457079-4`}
          />
          <script>
            {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'UA-156457079-4');
                `}
          </script>
          <html lang="en" />
        </Helmet>
        <div className={isPreloaded ? " main-body  is-preload" : " main-body"}>
          <div id="page-wrapper">{children}</div>
        </div>
        <Footer />
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
