import React, { Component } from "react";

/*
 * Assets
 */
import stickerArduino from "../assets/images/sticker-arduino.png";
import feriaMaker from "../assets/images/feriamaker.png";
import cruzIcon from "../assets/images/cruz-2.png";

class Convocatoria extends Component {
  render() {
    return (
      <section id="Convocatoria" className="grid-container">
        <div className="section-a">
          <p className="section--title">
            <img src={cruzIcon} alt="cruz icon" className="" />
            <h1>Feria Maker</h1>
          </p>
          <h2 className="section--subtitle">¡Feria Maker Online!</h2>
          <p>
            Visita la Feria Maker virtual del
            <spam className="primary-text">ARDUINO</spam>
            <spam className="secundary-text">DAY</spam>
            <spam className="tertiary-text">LATAM</spam> 2021. Navega por los
            pasillos del Museo Franz Mayer y descubre más de 30 proyectos de los
            mejores creadores del <spam className="primary-text">ARDUINO</spam>
            <spam className="secundary-text">DAY</spam>
            <spam className="tertiary-text"> 2021.</spam>
          </p>
          <div className="register">
            <br />
            <a href="/feria-maker/">¡INGRESA!</a>
          </div>
        </div>
        <div className="section-b">
          <img src={stickerArduino} alt="arduno day logo 2" />
          <a href="/feria-maker/">
            <img src={feriaMaker} alt="Logo de la feria maker" />
          </a>
        </div>
      </section>
    );
  }
}

export default Convocatoria;
