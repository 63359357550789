import React, { Component } from "react";

/*
 * Assets
 */
import cruzIcon from "../assets/images/cruz.png";

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display_form: true,
      display_success: false,
      submitForm: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  // add mailerlite code
  componentDidMount() {
    const js = `
    function ml_webform_success_2724758() {
      var $ = ml_jQuery || jQuery;
      $('.ml-subscribe-form-c5h1x4 .ml-block-success').show();
      $('.ml-subscribe-form-c5h1x4 .ml-block-form').hide();
    };
  `;
    const script = document.createElement("script");
    const scriptText = document.createTextNode(js);
    script.appendChild(scriptText);
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.src =
      "https://static.mailerlite.com/js/w/webforms.min.js?v42b571e293fbe042bc115150134382c9";
    document.body.appendChild(script2);
  }

  handleClick(e) {
    console.log("handleSubmit");
    this.setState({
      submitForm: true,
    });
  }
  render() {
    return (
      <section id="Subscribe" className="grid-container">
        <div className="section-a">
          <div>
            {
              // eslint-disable-next-line
            }
            <iframe
              src="https://www.youtube.com/embed/K0GY16UCkhI"
              title="Arduino day video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
        <div className="section-b">
          <div>
            <div
              className="content"
              style={!this.state.submitForm ? {} : { display: "none" }}
            >
              <img src={cruzIcon} alt="icon" className="icon" />
              <h2 className="subscribe--title">
                ¿Quieres recibir
                <br />
                nuestro boletín de
              </h2>
              <p className="subscribe--subtitle">
                las últimas novedades <br />
                del evento?
              </p>

              <form
                className="ml-block-form"
                action="https://app.mailerlite.com/webforms/submit/c5h1x4"
                data-id="c5h1x4"
                data-code="c5h1x4"
                method="post"
                onSubmit={this.handleClick}
                target="_blank"
              >
                <div class="ml-form-formContent">
                  <div class="ml-form-fieldRow ml-last-item">
                    <div class="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                      <input
                        type="email"
                        className=" subscribe--email"
                        data-inputmask=""
                        name="fields[email]"
                        placeholder="Ingresa tu email"
                        autocomplete="email"
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={!this.state.submitForm ? {} : { display: "none" }}
                  class="ml-form-embedSubmit"
                >
                  <input type="hidden" name="ml-submit" value="1" />
                  <button type="submit" class="subscribe--button">
                    ENVIAR
                  </button>
                </div>
              </form>
            </div>
            <div
              id="mlb2-c5h1x4"
              className="ml-subscribe-form ml-subscribe-form-c5h1x4"
            >
              <div className="ml-vertical-align-center">
                <div
                  style={!this.state.submitForm ? { display: "none" } : {}}
                  className="subscribe-form ml-block-success"
                >
                  <div className="form-section">
                    <p>
                      <b>
                        <u>
                          Thank you, you have successfully subscribed to our
                          announcement list!
                        </u>
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Subscribe;
