/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import transparentBg from "../../assets/images/bg-flag.png";

export default class Speaker extends Component {
  render() {
    return (
      <article className="flex-article">
        <div className="flex-image-container-speaker">
          <img
            src={this.props.pic}
            alt={this.props.alt}
            className="article-speaker-pic"
          />
        </div>
        <div>
          <FontAwesomeIcon
            icon={faChevronRight}
            size="2x"
            className={this.props.className}
          />
          <img className={this.props.flag} src={transparentBg} />
          <h3>{this.props.name}</h3>
          <h4 className="article-speaker-pic--sub">{this.props.sub}</h4>
        </div>
      </article>
    );
  }
}
