import React, { Component } from "react";

/*
 * Assets
 */
import toolsImage from "../assets/images/tools.png";
import cruzIcon from "../assets/images/cruz.png";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section id="about" className="grid-container">
        <div className="section-a">
          <img src={toolsImage} alt="herramientas de desarrollo" />
        </div>
        <div className="section-b">
          <p>
            <spam className="primary-text">ARDUINO</spam>
            <spam className="secundary-text">DAY</spam> es un día especial para
            nosotros y sabemos que también lo es para ti, y por ti, la seguridad
            de todos, este años será vía online y totalmente gratuito.
          </p>
          <p>
            <spam className="primary-text">ARDUINO</spam>
            <spam className="tertiary-text">DAY</spam>
            <spam className="quaternary-text">LATAM</spam> es un día especial
            para nosotros y sabemos que también lo es para ti, y por ti y por la
            seguridad de todos, la edición de este año será vía online y
            totalmente gratis.
          </p>
          <p>
            Ven a{" "}
            <spam className="primary-text">conocer, escuchar y aprender </spam>{" "}
            de los más grandes exponentes de la comunidad{" "}
            <spam className="tertiary-text">LATINOAMERICANA</spam>{" "}
            <spam className="secundary-text">DE</spam>{" "}
            <spam className="primary-text">ARDUINO.</spam>
          </p>
          <p>
            <img
              align="right"
              src={cruzIcon}
              alt="icon x"
              className="cruzIcon"
            />
          </p>
        </div>
      </section>
    );
  }
}

export default About;
