import React, { Component } from "react";

/*
 * Assets
 */
import heartIcon from "../assets/images/heart.png";
import ecLogo from "../assets/images/ec-logo.png";
import ilceLogo from "../assets/images/ilce-logo.png";
import hacedoresLogo from "../assets/images/hacedores-logo.png";

class Sponsors extends Component {
  render() {
    return (
      <section id="Sponsors" className="grid-container">
        <div className="section-a">
          <h2>
            HECHO CON{" "}
            <span>
              <img src={heartIcon} alt="heart icon" />
            </span>
          </h2>
        </div>
        <div className="section-b">
          <img className="sponsors-img" src={ilceLogo} alt="logo" />
        </div>
        <div className="section-c">
          <img
            className="sponsors-img logo-xs"
            src={hacedoresLogo}
            alt="logo"
          />
        </div>
        <div className="section-d">
          <img className="sponsors-img" src={ecLogo} alt="logo" />
        </div>
      </section>
    );
  }
}

export default Sponsors;
