import React, { Component } from "react";

/**
 ** Components
 */
import Tabs from "./widgets/Tabs";
import Tab from "./widgets/Tab";
import SpeakersLine from "./widgets/SpeakersLine";

/*
 * * ASSETS
 */
import cruzIcon from "../assets/images/cruz-2.png";
import speaker1 from "../assets/images/image-profile-David-Cuartielles.png";
import speaker2 from "../assets/images/image-profile-Angel-Isidro.png";
import speaker3 from "../assets/images/image-profile-Antonio-Mendoza.png";
//import speaker4 from "../assets/images/image-profile-cat-sad.png";
import speaker5 from "../assets/images/image-profile-Cristobal.png";
import speaker6 from "../assets/images/image-profile-Jesus-Cortes.png";
import speaker7 from "../assets/images/image-profile-Jose-Carlos.png";
import speaker8 from "../assets/images/image-profile-Kawaii-Tech.png";
import speaker9 from "../assets/images/image-profile-kiara-Navarro.png";
import speaker10 from "../assets/images/image-profile-María-Hernandez.png";
import speaker11 from "../assets/images/image-profile-Moon-Makers.png";
import speaker12 from "../assets/images/image-profile-Nagil-Vallejos.png";
import speaker13 from "../assets/images/image-profile-Slavador-Mendoza.png";
//import speaker14 from "../assets/images/image-profile-Gato-04.png";
//import speaker15 from "../assets/images/image-profile-Gato-05.png";
//import speaker16 from "../assets/images/image-profile-Gato-06.png";
//import speaker17 from "../assets/images/image-profile-Gato-07.png";
import speaker18 from "../assets/images/image-profile-Xyoli-Perez.jpg";
import speaker19 from "../assets/images/image-profile-Humberto-Valadez.jpg";
import speaker20 from "../assets/images/image-profile-Cosmoblastos.png";
import speaker21 from "../assets/images/image-profile-Guillermina-Jimenez.png";
import speaker22 from "../assets/images/image-profile-Antonio-Toriz.png";
import speaker23 from "../assets/images/image-profile-Gabino-Monroy.png";
import speaker24 from "../assets/images/image-profile-Edukreativos.png";
import speaker25 from "../assets/images/image-profile-Daniel-Sandoval.png";
import speaker26 from "../assets/images/image-profile-Marisa-Elena.png";
import speaker27 from "../assets/images/image-profile-ILCE.png";
import speaker28 from "../assets/images/image-profile-Antonio.jpg";
import speaker29 from "../assets/images/image-profile-Andres-Sabas.png";

import youtubeIcon from "../assets/images/youtube-icon.png";
class Timeline extends Component {
  render() {
    return (
      <section id="tabs">
        <p className="section--title">
          <h1>PROGRAMA</h1>
          <img src={cruzIcon} alt="cruz icon" className="" />
        </p>
        <Tabs>
          <Tab label="ELECTRONIC CATS" key="tab-1">
            <div className="tabs-grid-container">
              <div className="tabs-grid-a">
                <div class="timeline">
                  <ul>
                    <SpeakersLine
                      time="9:00-10:00"
                      title="Bienvenida Arduino Day"
                      speaker="David Cuartielles"
                      speakerImg={speaker1}
                      alt="David Cuartielles"
                    />
                    <SpeakersLine
                      time="10:00-11:00"
                      title="Deploy Proyecto Arduino con Arduino-cli y Github-Accions"
                      speaker="Jose Carlos Garcia Diaz"
                      speakerImg={speaker7}
                      alt="Jose Carlos Garcia Diaz"
                    />
                    <SpeakersLine
                      time="11:00-12:00"
                      title="Reciclaje Maker desde casa con Arduino"
                      speaker="MoonMakers"
                      speakerImg={speaker11}
                      alt="MoonMakers"
                    />
                    <SpeakersLine
                      time="12:00 - 13:00"
                      title="Redes neuronales con arduino"
                      speaker="Nagib Luis Vallejos Maman"
                      speakerImg={speaker12}
                      alt="Nagib Luis Vallejos Maman"
                    />
                    <SpeakersLine
                      time="13:00 - 14:00"
                      title="NFC con Arduino"
                      speaker="Salvador Mendoza"
                      speakerImg={speaker13}
                      alt="Salvador Mendoza"
                    />
                    <SpeakersLine
                      time="14:00 - 15:00"
                      title="Debbug con OpenOCD para sistemas embebidos"
                      speaker="Kiara Navarro"
                      speakerImg={speaker9}
                      alt="Kiara Navarro"
                    />
                  </ul>
                </div>
              </div>
              <div className="tabs-grid-b">
                <div class="timeline">
                  <ul>
                    <SpeakersLine
                      time="15:00 - 16:00"
                      title="Simulando misiones espaciales con arduino"
                      speaker="Andrés Sabas Jiménez"
                      speakerImg={speaker29}
                      alt="Andrés Sabas Jiménez"
                    />
                    <SpeakersLine
                      time="16:00 - 17:00"
                      title="Implementación de Controladores en Arduino"
                      speaker="Humberto Valadez"
                      speakerImg={speaker19}
                      alt="Humberto Valadez"
                    />
                    <SpeakersLine
                      time="17:00 - 18:00"
                      title="XIDE - IoT para Todos"
                      speaker="Jesús Cortes"
                      speakerImg={speaker6}
                      alt="Jesús Cortes"
                    />
                    <SpeakersLine
                      time="18:00 - 19:00"
                      title="Badges Electronicos con Arduino"
                      speaker="KawaiiTech"
                      speakerImg={speaker8}
                      alt="KawaiiTech"
                    />{" "}
                    <SpeakersLine
                      time="19:00 - 20:00"
                      title="Red de sensores de bajo costo para monitorear la calidad de aire"
                      speaker="Angel Isidro"
                      speakerImg={speaker2}
                      alt="Angel Isidro"
                    />
                  </ul>
                </div>
              </div>
            </div>
            <h2 className="tab-1">Entra a la transmision en vivo</h2>
            <a href="https://www.youtube.com/channel/UCA9pODMFvrLEAB9vYWiLebg">
              <img
                alt="logo youtube"
                src={youtubeIcon}
                className="logo-youtube"
              />
            </a>
          </Tab>
          <Tab label="HACEDORES" className="tab-2">
            <div className="tabs-grid-container tab-2">
              <div className="tabs-grid-a">
                <div class="timeline">
                  <ul>
                    <SpeakersLine
                      time="09:00 - 10:00"
                      title="Apertura y Bienvenida al evento Arduino Day"
                      speaker="David Cuartielles"
                      speakerImg={speaker1}
                      alt="Image David Cuartielles"
                    />
                    <SpeakersLine
                      time="12:00-13:00"
                      title="La combi de la ciencia, ¡la ciencia que se mueve!"
                      speaker='Cristóbal García "Chico Partículas"'
                      speakerImg={speaker5}
                      alt='Cristóbal García "Chico Partículas"'
                    />
                  </ul>
                </div>
              </div>
              <div className="tabs-grid-b">
                <div class="timeline">
                  <ul>
                    <SpeakersLine
                      time="15:00 - 16:00"
                      title="El paso de una mujer en la sismología; El uso Arduino para un sismómetro mecánico."
                      speaker="Dra. Xyoli Pérez Campos"
                      speakerImg={speaker18}
                      alt="Dra. Xyoli Pérez Campos"
                    />
                    <SpeakersLine
                      time="16:00 - 17:00"
                      title="El uso de Arduino para un sismómetro mecánico"
                      speaker="Antonio de J. Mendoza"
                      speakerImg={speaker28}
                      alt="Antonio de J. Mendoza"
                    />
                  </ul>
                </div>
              </div>
            </div>
            <h2 className="tab-2">Entra a la transmision en vivo</h2>

            <a href="https://www.youtube.com/user/hacedorescom">
              <img
                alt="logo youtube"
                src={youtubeIcon}
                className="logo-youtube"
              />
            </a>
          </Tab>
          <Tab label="ILCE" className="tab-3">
            <div className="tabs-grid-container tab-3">
              <div className="tabs-grid-a">
                <div class="timeline">
                  <ul>
                    <SpeakersLine
                      time="09:00 - 10:00"
                      title="Apertura y Bienvenida al evento Arduino Day"
                      speaker="David Cuartielles"
                      speakerImg={speaker1}
                      alt="Image David Cuartielles"
                    />
                    <SpeakersLine
                      time="10:00 - 10:45"
                      title="Taller :La fábrica de mermelada de Marcos"
                      speaker="Marisa Elena Conde"
                      speakerImg={speaker26}
                      alt="Marisa Elena Conde"
                    />
                    <SpeakersLine
                      time="10:50 - 11:35"
                      title="Charla:La computación física con Arduino como  estrategia para favorecer las habilidades STEAM"
                      speaker="Guillermina Jiménez Rasgado"
                      speakerImg={speaker21}
                      alt="Guillermina Jiménez Rasgado"
                    />
                    <SpeakersLine
                      time="11:40 - 12:25"
                      title="Taller: Arduino para los más pequeños"
                      speaker="Daniel Sandoval Villa"
                      speakerImg={speaker25}
                      alt="Daniel Sandoval Villa"
                    />
                    <SpeakersLine
                      time="12:30-13:15"
                      title='Charla: Sustentabilidad con el "pedaleo eléctrico"'
                      speaker="Gabino Monroy"
                      speakerImg={speaker23}
                      alt="Gabino Monroy"
                    />
                  </ul>
                </div>
              </div>
              <div className="tabs-grid-b">
                <div class="timeline">
                  <ul>
                    <SpeakersLine
                      time="13:20-14:05"
                      title="Taller: Misión Espacial Arduino UNO"
                      speaker="Edukreativos"
                      speakerImg={speaker24}
                      alt="Edukreativos"
                    />
                    <SpeakersLine
                      time="14:10-14:55"
                      title="Charla: Cultura Maker y la era del hágalo usted mismo."
                      speaker="Antonio Toriz Cureño"
                      speakerImg={speaker22}
                      alt="Antonio Toriz Cureño"
                    />
                    <SpeakersLine
                      time="15:00-15:45"
                      title="Charla:Oxímetro. Un proyecto de telemedicina"
                      speaker="Cosmoblastos"
                      speakerImg={speaker20}
                      alt="Cosmoblastos"
                    />
                    <SpeakersLine
                      time="15:45-16:00"
                      title="Conclusiones"
                      speaker="ILCE y ponentes"
                      speakerImg={speaker27}
                      alt="Pepe Ruiz"
                    />
                  </ul>
                </div>
              </div>
            </div>

            <h2 className="tab-3">Entra a la transmision en vivo</h2>
            <a href="https://www.youtube.com/watch?v=9yK1-b_V7hs">
              <img
                alt="logo youtube"
                src={youtubeIcon}
                className="logo-youtube"
              />
            </a>
          </Tab>
        </Tabs>
      </section>
    );
  }
}
export default Timeline;
