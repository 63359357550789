import React, { Component } from "react";

class InfoBox extends Component {
  render() {
    return (
      <div className="info-box">
        <img src={this.props.icon} className="" alt={this.props.alt} />
        <spam className="info-text">{this.props.info}</spam>
      </div>
    );
  }
}

export default InfoBox;
