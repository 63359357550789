import React, { Component } from "react";

// Assets
import stickerArduino from "../assets/images/sticker-arduino.png";
import cruzIcon from "../assets/images/cruz-2.png";
import speaker1 from "../assets/images/image-profile-David-Cuartielles.png";
import speaker2 from "../assets/images/image-profile-Angel-Isidro.png";
import speaker3 from "../assets/images/image-profile-Antonio-Mendoza.png";
import speaker4 from "../assets/images/image-profile-cat-sad.png";
import speaker5 from "../assets/images/image-profile-Cristobal.png";
import speaker6 from "../assets/images/image-profile-Jesus-Cortes.png";
import speaker7 from "../assets/images/image-profile-Jose-Carlos.png";
import speaker8 from "../assets/images/image-profile-Kawaii-Tech.png";
import speaker9 from "../assets/images/image-profile-kiara-Navarro.png";
import speaker10 from "../assets/images/image-profile-Andres-Sabas.png";
import speaker11 from "../assets/images/image-profile-Moon-Makers.png";
import speaker12 from "../assets/images/image-profile-Nagil-Vallejos.png";
import speaker13 from "../assets/images/image-profile-Slavador-Mendoza.png";
import speaker14 from "../assets/images/image-profile-Humberto-Valadez.jpg";

// Components
import Speaker from "../components/widgets/Speaker";

export default class Speakers extends Component {
  render() {
    return (
      <section id="Speakers" className={`wrapper`}>
        <div className="grid-container Speakers">
          <div className="section-a">
            <p className="section--title">
              <img src={cruzIcon} alt="cruz icon" className="" />
              <h1>PONENTES</h1>
            </p>
          </div>
          <div className="section-b">
            <img src={stickerArduino} alt="arduno day logo 2" />
          </div>
        </div>
        <div className="inner">
          <section className="flex-container">
            <Speaker
              alt="David Cuartielles"
              pic={speaker1}
              className="icon-arrow-1 icon-flag"
              name="David Cuartielles"
              sub="Co-Fundadador de abierto.cc y Arduino"
              flag="flag-es"
            />
            <Speaker
              alt="Salvador Mendoza"
              pic={speaker13}
              className="icon-arrow-3 icon-flag"
              name="Salvador Mendoza"
              sub="Security researcher | payment systems | RFID/NFC trainer | book author"
              flag="flag-mx"
            />
            <Speaker
              alt="Andrés Sabas Jiménez"
              pic={speaker10}
              className="icon-arrow-1 icon-flag"
              name="Andrés Sabas Jiménez"
              sub="Irreverente, Maker, me gusta provocar que las cosas sucedan, hago Hardware Libre en @electronicats.
              "
              flag="flag-mx"
            />
            <Speaker
              alt="
              Ángel Daniel Isidro Rodríguez image speaker
              "
              className="icon-arrow-2 icon-flag"
              pic={speaker2}
              name="
              Ángel Isidro Rodríguez 
              "
              sub="Investigador y Desarrollador de dispositivos IoT"
              flag="flag-sal"
            />

            <Speaker
              alt="Moon Makers"
              pic={speaker11}
              name="Moon Makers"
              className="icon-arrow-4 icon-flag"
              sub="Un canal de YouTube donde promueven la ciencia, la tecnología y el movimiento maker."
              flag="flag-mx"
            />
            <Speaker
              alt="Kiara Navarro"
              pic={speaker9}
              className="icon-arrow-1 icon-flag"
              name="Kiara Navarro"
              sub="Empedernida en sistemas embebidos."
              flag="flag-pan"
            />
            <Speaker
              alt="Kawaii Tech"
              pic={speaker8}
              className="icon-arrow-2 icon-flag"
              name="KawaiiTech"
              sub="Makers"
              flag="flag-mx"
            />
            <Speaker
              alt="Jose Carlos Garcia Diaz"
              pic={speaker7}
              className="icon-arrow-3 icon-flag"
              name="Jose Carlos Garcia Diaz"
              sub="Maker, Emprendedor y Otaku"
              flag="flag-sal"
            />
            <Speaker
              alt="Jesús Adán Cortés"
              pic={speaker6}
              className="icon-arrow-4 icon-flag"
              name="Jesús Adán Cortés"
              sub="Emprendedor y apasionado de la tecnología relacionada con IoT. "
              flag="flag-mx"
            />
            <Speaker
              alt="Nagib Luis Vallejos Maman"
              pic={speaker12}
              className="icon-arrow-1 icon-flag"
              name="Nagib Luis Vallejos Maman"
              sub="Youtuber enfocado al manejo de microcontroladores"
              flag="flag-bol"
            />
            <Speaker
              alt="Humberto Valadez Rangel"
              pic={speaker14}
              className="icon-arrow-1 icon-flag"
              name="Humberto Valadez Rangel"
              sub="Matemático. Especialista en Sistemas de Control y Robótica"
              flag="flag-mx"
            />
          </section>
        </div>
      </section>
    );
  }
}
