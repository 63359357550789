import React, { Component } from "react";

/*
 * Assets
 */
import logoArduinoDay from "../assets/images/logo-arduinoday.png";
import clockIcon from "../assets/images/clock.png";
import ticketIcon from "../assets/images/ticket.png";
import eventoIcon from "../assets/images/evento.png";
import arrowIcon from "../assets/images/arrow.png";
import banner from "../assets/images/banner.png";
import registerIcon from "../assets/images/register-icon.png";

/*
 * Components
 */
import InfoBox from "./widgets/InfoBox";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section id="Header" className="grid-container">
        <div className="section-a">
          <img
            src={logoArduinoDay}
            alt="logo Arduino day latam"
            className="logo-header"
          />
        </div>
        <div className="section-b">
          <spam className="spam-date">20 MARZO 2021</spam>
        </div>
        <div className="section-c">
          {/*<div className="register">
            <p>
              <img src={registerIcon} align="left" alt="register icon" />
              <spam className="register--title">REGISTRA</spam>
              <spam className="register--subtitle">TU PROYECTO</spam>
            </p>
    </div>*/}
          <div className="date-register">
            {/*<spam className="date--title">
              <spam className="date--number">08</spam> MARZO
            </spam>
  <br />*/}
            <span className="span-sticker date--subtitle">
              CONECTATE, <br />
              COMPARTE <br />Y ANOTA
            </span>
          </div>
        </div>
        <div className="section-d">
          <InfoBox icon={clockIcon} alt="clock icon" info="09:00 hrs" />
        </div>
        <div className="section-e">
          <InfoBox
            icon={ticketIcon}
            alt="ticket icon"
            info={`Totalmente Gratuito`}
          />
        </div>
        <div className="section-f">
          <InfoBox icon={eventoIcon} alt="evento icon" info="Evento Online" />
        </div>
        <div className="section-g">
          <img src={banner} alt="banner arduino day" />
        </div>
        <div className="section-h">
          <div className="info-box-arrow">
            <a href="#about">
              <img src={arrowIcon} alt="arrow" />
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default Header;
