import React, { Component } from "react";

/*
 * Assets
 */
import logo7segmentos from "../assets/images/7sponsor.jpg";
import logo330ohms from "../assets/images/330ohms.png";
import logoAbiertocc from "../assets/images/logo_abierto.png";
import logoFranz from "../assets/images/franz-mayer.svg";
import logoMouser from "../assets/images/mouser-electronics.png";
import logoGob from "../assets/images/logo-gob.png";
import logoIdscea from "../assets/images/logo-idscea.png";
import logoSedec from "../assets/images/logo-sedec.png";
import logoWurth from "../assets/images/wurth-electronic.png";
class Footer extends Component {
  render() {
    return (
      <section id="Footer" className="grid-container">
        <div className="section-a">
          <h2 className="tertiary-text">CON COLABORACÍON DE</h2>
        </div>
        <div className="section-b">
          <article class="footer-article">
            <div class="flex-sponsor-container">
              <a href="https://arduino.mx/">
                <img
                  src={logo7segmentos}
                  alt="Logo La Hora Maker"
                  class="article-ally-pic logo-1"
                />
              </a>
            </div>
          </article>
          <article class="footer-article">
            <div class="flex-sponsor-container">
              <a href="https://www.330ohms.com/">
                <img
                  src={logo330ohms}
                  alt="Logo 330"
                  class="article-ally-pic logo-2"
                />
              </a>
            </div>
          </article>
          <article class="footer-article">
            <div class="flex-sponsor-container">
              <a href="https://abierto.cc/">
                <img
                  src={logoAbiertocc}
                  alt="Logo abierto cc"
                  class="article-ally-pic logo-3"
                />
              </a>
            </div>
          </article>

          <article class="footer-article">
            <div class="flex-sponsor-container">
              <a href=" https://franzmayer.org.mx/">
                <img
                  src={logoFranz}
                  alt="Logo La franzmayer"
                  class="article-ally-pic logo-4"
                />
              </a>
            </div>
          </article>
          <article class="footer-article">
            <div class="flex-sponsor-container">
              <a href="https://www.we-online.com/web/en/wuerth_elektronik/start.php">
                <img
                  src={logoWurth}
                  alt="Logo Wurth"
                  class="article-ally-pic logo-5"
                />
              </a>
            </div>
          </article>
          <article class="footer-article">
            <div class="flex-sponsor-container">
              <a href="https://www.mouser.mx/">
                <img
                  src={logoMouser}
                  alt="Logo La franzmayer"
                  class="article-ally-pic logo-5"
                />
              </a>
            </div>
          </article>
          <article class="footer-article">
            <div class="flex-sponsor-container">
              <a href="https://www.aguascalientes.gob.mx/">
                <img
                  src={logoGob}
                  alt="Logo La franzmayer"
                  class="article-ally-pic logo-6"
                />
              </a>
            </div>
          </article>
          <article class="footer-article">
            <div class="flex-sponsor-container">
              <a href="https://www.aguascalientes.gob.mx/idscea/">
                <img
                  src={logoIdscea}
                  alt="Logo La franzmayer"
                  class="article-ally-pic logo-7"
                />
              </a>
            </div>
          </article>
          <article class="footer-article">
            <div class="flex-sponsor-container">
              <a href="https://www.aguascalientes.gob.mx/sedec/">
                <img
                  src={logoSedec}
                  alt="Logo La franzmayer"
                  class="article-ally-pic logo-8"
                />
              </a>
            </div>
          </article>
        </div>
      </section>
    );
  }
}

export default Footer;
