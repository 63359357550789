import React, { Component } from "react";

export default class SpeakersLine extends Component {
  render() {
    return (
      <li>
        <div class="people">
          <img src={this.props.speakerImg} alt={this.props.alt} />
        </div>
        <div class="bullet green"></div>
        <div class="time">{this.props.time}</div>
        <div class="desc">
          <h3>{this.props.title}</h3>
          <h4>{this.props.speaker}</h4>
        </div>
      </li>
    );
  }
}
